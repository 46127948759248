import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { PORT } from "../Api/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./form.css";

import AdminNavbar from "./AdminNavbar";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { IoMdArrowBack } from "react-icons/io";
import Layout from "../lib/Layout";
import AddBackbtn from "../lib/AddBackbtn";
import { LanguageContext } from "../lib/LanguageContext";
const EditHouseData = () => {
  const { language } = useContext(LanguageContext);
  const [details, setDetails] = useState(null);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    houseNo: Yup.string().required("HouseNO is required"),
    ownerName: Yup.string().required("OwnerName is required"),
    // ownerNameHindi: Yup.string().required("Owner Name in Hindi is required"),
    contact: Yup.string()
      .required("Contact number is required")
      .matches(/^\d{10}$/, "Contact number must be a 10-digit number"),
    alternateContact: Yup.string().matches(
      /^\d{10}$/,
      "Alternate contact number must be a 10-digit number"
    ),
    aadhaarNumber: Yup.string().matches(
      /^\d{12}$/,
      "Aadhaar number must be a 12-digit number"
    ),
    address: Yup.string().required("Address is required"),
    gender: Yup.string().required("Gender is required"),
    // blockNo: Yup.string().required("Block number is required"),
    vehicleInfo: Yup.string().required("Vehicle information is required"),
  });

  const formik = useFormik({
    initialValues: {
      houseNo: "",
      ownerName: "",
      // ownerNameHindi: "",
      contact: "",
      alternateContact: "",
      aadhaarNumber: "",
      address: "",
      gender: "",
      blockNumber: "",
      vehicleInfo: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        // Show confirmation modal
        const result = await Swal.fire({
          title: "Do you want to update this entry?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });

        if (result.isConfirmed) {
          await axios.put(`${PORT}/houseDetailsUpdate/${id}`, values);
          localStorage.removeItem("houseId");
          navigate("/admin/house-data");
        }
      } catch (error) {
        console.error("Error updating entry:", error);
      }
    },
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`${PORT}/houseDetailsUpdate/${id}`);
        formik.setValues(response.data.details);
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };
    fetchDetails();
  }, [id]);
  return (
    <>
      <Layout>
        <div className="table_heading">
          <h5 className="heading_top">
            {language === "hindi"
              ? "Edit House Details"
              : "घर का विवरण संपादित करें"}
          </h5>
          <div className="hrline"></div>
        </div>
        <AddBackbtn />
        <br />
        <br />
        <br />
        <form className="edit-form-container" onSubmit={formik.handleSubmit}>
          <div>
            <label className="editLabel" htmlFor="houseNo">
              {language === "hindi" ? "House No." : "मकान नंबर"}
            </label>
            <br />
            <input
              type="text"
              id="houseNo"
              name="houseNo"
              value={formik.values.houseNo}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.houseNo && (
              <div className="error-house">{formik.errors.houseNo}</div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="ownerName">
              {language === "hindi" ? "  Owner Name" : "मालिक का नाम"}
            </label>
            <br />
            <input
              type="text"
              id="ownerName"
              name="ownerName"
              value={formik.values.ownerName}
              onChange={formik.handleChange}
              autoComplete="off"
              className="edit-input"
            />
            {formik.errors.ownerName && (
              <div className="error-house">{formik.errors.ownerName}</div>
            )}
          </div>

          <div>
            <label className="editLabel" htmlFor="contact">
              {language === "hindi" ? "  Contact No." : "संपर्क नंबर"}
            </label>
            <br />
            <input
              type="text"
              id="contact"
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.contact && (
              <div className="error-house">{formik.errors.contact}</div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="alternateContact">
              {language === "hindi"
                ? " Alternate Contact No."
                : "वैकल्पिक संपर्क नंबर"}
            </label>
            <br />
            <input
              type="text"
              id="alternateContact"
              name="alternateContact"
              value={formik.values.alternateContact}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.alternateContact && (
              <div className="error-house">
                {formik.errors.alternateContact}
              </div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="adhaarNo">
              {language === "hindi" ? "Aadhaar No." : "आधार नं"}
            </label>
            <br />
            <input
              type="text"
              id="adhaarNo"
              name="aadhaarNumber"
              value={formik.values.aadhaarNumber}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.aadhaarNumber && (
              <div className="error-house">{formik.errors.aadhaarNumber}</div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="address">
              {language === "hindi" ? "Address." : "पता"}
            </label>
            <br />
            <input
              type="text"
              id="address"
              name="address"
              value={formik.values.address}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.address && (
              <div className="error-house">{formik.errors.address}</div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="blockNo">
              {language === "hindi" ? "Block No." : "ब्लॉक नं"}
            </label>
            <br />
            <input
              type="text"
              id="blockNo"
              name="blockNo"
              value={formik.values.blockNumber}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.blockNo && (
              <div className="error-house">{formik.errors.blockNo}</div>
            )}
          </div>
          <div>
            <label className="editLabel" htmlFor="vehicleInfo">
              {language === "hindi" ? "Vehicle Information" : "वाहन की जानकारी"}
            </label>
            <br />
            <input
              type="text"
              id="vehicleInfo"
              name="vehicleInfo"
              value={formik.values.vehicleInfo}
              onChange={formik.handleChange}
              className="edit-input"
            />
            {formik.errors.vehicleInfo && (
              <div className="error-house">{formik.errors.vehicleInfo}</div>
            )}
          </div>
          <div className="edit_house_gender">
            <label className="editLabel" htmlFor="gender">
              {language === "hindi" ? "Gender" : "लिंग"}
            </label>
            <br />
            <select
              id="gender"
              name="gender"
              value={formik.values.gender}
              onChange={formik.handleChange}
              className="edit-input"
            >
              <option value="" label="Select gender" />
              <option value="Male" label="Male" />
              <option value="Female" label="Female" />
            </select>
            {formik.errors.gender && (
              <div className="error-house">{formik.errors.gender}</div>
            )}
          </div>
          <div className="edit_house_button">
            <button className="edit-button" type="submit">
              {language === "hindi" ? "   Update" : "  अद्यतन"}
            </button>
          </div>
        </form>
      </Layout>
    </>
  );
};

export default EditHouseData;

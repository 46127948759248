import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { FaUser, FaAddressCard, FaImages, FaCar } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { IoMdClose } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import { LanguageContext } from "../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
const GetHouseDetail = ({ item }) => {
  const { language } = useContext(LanguageContext);
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <h5 className="all_data mt-3">
              <IoEyeSharp
                data-toggle="tooltip"
                className="eyes_view"
                data-placement="top"
                title={language === "hindi" ? "view" : "देखना"}
              />
            </h5>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "50vw",
              maxWidth: "50vw",
            }}
          >
            <div className="heading_user">
              <div>
                <Button
                  className="close_btn"
                  onClick={toggleDrawer(anchor, false)}
                >
                  <IoMdClose className="close_icons_house" />
                </Button>
              </div>
              <div className="user_detail">
                <h4>
                  {language === "hindi"
                    ? "House Owner Details"
                    : "मकान मालिक का विवरण"}
                </h4>
              </div>
            </div>
            <hr className="hr_line" />
            <List>
              <div className="main_content_div">
                <div className="content1_left_div ">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp; {language === "hindi" ? "Name " : "नाम"}
                  </h3>
                  <h3 className="house_title_left">
                    <ImUsers />
                    &nbsp;{language === "hindi" ? "Gender " : "लिंग"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{language === "hindi" ? "Address " : " पता"}
                  </h3>
                  <h3 className="house_title_left">
                    <MdContactPhone />
                    &nbsp;{language === "hindi" ? "Contact " : " संपर्क"}
                  </h3>
                  <h3 className="house_title_left">
                    <MdContactPhone />
                    &nbsp;
                    {language === "hindi"
                      ? "Alternate Contact"
                      : "वैकल्पिक संपर्क"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{language === "hindi" ? "Block No." : "ब्लॉक नं."}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{language === "hindi" ? "Aadhar No." : " आधार नं."}
                  </h3>
                  <h3 className="house_title_left">
                    <FaCar />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? "Vehical Info "
                      : "वाहन संबंधी जानकारी"}
                  </h3>
                </div>
                <div className="content1_right_div">
                  <React.Fragment key={item.id}>
                    <h3 className="user_images_heading">{item.ownerName}</h3>
                    <h3 className="user_images_heading">{item.gender}</h3>
                    <h3 className="user_images_heading">{item.address}</h3>
                    <h3 className="user_images_heading">{item.contact}</h3>
                    <h3 className="user_images_heading">
                      {item.alternateContact}
                    </h3>
                    <h3 className="user_images_heading">{item.blockNumber}</h3>
                    <h3 className="user_images_heading">
                      {item && item.aadhaarNumber}
                    </h3>
                    <h3 className="user_images_heading">{item.vehicleInfo}</h3>
                  </React.Fragment>
                </div>
              </div>

              <div className="user_images_bottom_div  house_list_view">
                <h3 className="user_images_heading user_images_heading_house">
                  <FaImages />
                  &nbsp; {language === "hindi" ? "Images " : "इमेजिस "}
                </h3>
                <div className="user_images_div">
                  {Array.isArray(item.ownerImages) &&
                    item.ownerImages.map((imgPath, index) => {
                      const modifiedPath = imgPath.substring(
                        imgPath.indexOf("public/") + "public/".length
                      );
                      const imageUrl = `/${modifiedPath}`;

                      return <img key={index} src={imageUrl} alt="" />;
                    })}
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default GetHouseDetail;

import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { IoArrowBack } from "react-icons/io5";
import { CiEdit } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { PORT } from "../Api/api";
import { ThreeCircles } from "react-loader-spinner";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import Layout from "../lib/Layout";
import { LanguageContext } from "../lib/LanguageContext";
const AdminProfileSetting = () => {
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingPermission, setLoadingPermission] = useState(true);
  const [guardData, setGuardData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const id = JSON.parse(localStorage.getItem("roleId"));
  useEffect(() => {
    const getGuardData = async () => {
      try {
        const response = await axios.get(
          `${PORT}/getEditWithSocietyUnion/${id}`
        );
        setGuardData(response.data.data[0]);
        setLoadingPermission(false);
      } catch (error) {
        console.error("Error fetching guard data:", error);
        setLoadingPermission(false);
      }
    };
    getGuardData();
  }, [guardData]);

  // Effect to check if logged in
  useEffect(() => {
    if (location.pathname === "/profileSetting") {
      const guardname = JSON.parse(localStorage.getItem("guardName"));
      if (!guardname) {
        navigate("/login");
      }
    }
  }, [location.pathname, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSubmit = async (values) => {
    try {
      const res = await axios.put(`${PORT}/editWithSocietyUnion/${id}`, values);
      setGuardData({ ...guardData, ...values });
      toast.success(res.data.message);
      toggleEditMode();
    } catch (error) {
      console.error("Error updating guard data:", error);
    }
  };
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to upload this image?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Upload",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        uploadImage(selectedFile);
      } else {
        console.log("Upload cancelled");
      }
    });
    const uploadImage = async (file) => {
      const formData = new FormData();
      formData.append("Ownerimage", file);
      await axios
        .put(`${PORT}/editAdminimage/${id}`, formData)
        .then((data) => {
          console.log("Image uploaded and API called successfully", data);
          Swal.fire("Success", "Image uploaded successfully!", "success");
        })
        .catch((error) => {
          console.error("Error uploading image", error);
          Swal.fire(
            "Error",
            "Failed to upload image. Please try again later.",
            "error"
          );
        });
    };
  };
  const [userRole, setUserRole] = useState();
  const guardImage = guardData?.Ownerimage
    ? guardData.Ownerimage.replace("public", "")
    : "";

  useEffect(() => {
    switch (guardData.defaultPermissionLevel) {
      case 4:
        setUserRole("Society Admin");
        break;
      case 3:
        setUserRole("Super Admin");
        break;
      case 2:
        setUserRole("Admin");
        break;

      default:
        break;
    }
  }, [guardData]);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name is too short - should be 2 chars minimum."),
    username: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "Invalid email format"
      ),
    userPhoneNo: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .min(10, "Phone number must be at least 10 digits")
      .max(15, "Phone number must be at most 15 digits"),
  });
  return (
    <Layout>
      <div className="profile_setting_main_div">
        {loadingPermission ? (
          <div className="three_circle_loader">
            <ThreeCircles
              visible={true}
              height={100}
              width={100}
              color="#5e72e4"
              ariaLabel="three-circles-loading"
            />
          </div>
        ) : (
          <div className="profile_inner_div_admin">
            <div className="profile-photo">
              <div className="back_profile">
                <IoArrowBack
                  onClick={handleBack}
                  className="back_profile_icon"
                />
                &nbsp;&nbsp;
                <span>{language === "hindi" ? "Profile" : " प्रोफ़ाइल "}</span>
              </div>

              <div className="profile-image-div ">
                {guardData?.Ownerimage?.length > 0 ? (
                  guardData?.Ownerimage && <img src={`${guardImage}`} alt="" />
                ) : (
                  <>
                    {(() => {
                      let initials = "";

                      if (guardData?.username) {
                        const words = guardData.username.split(" ");
                        if (words.length > 0) {
                          initials = words[0].substring(0, 1).toUpperCase();
                        }
                      }
                      return (
                        <>
                          <div className="heading_profile_div guardLogoImage">
                            <h1>{initials}</h1>
                          </div>
                        </>
                      );
                    })()}
                  </>
                )}
              </div>
              <div className="profile_image_main">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  id="profile-image-upload"
                  style={{
                    opacity: 0, // or visibility: "hidden"
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
                <label
                  htmlFor="profile-image-upload"
                  className="add-image-label"
                >
                  <CiEdit className=" add-image-icon" />
                </label>
              </div>
            </div>
            <div className="user-content">
              {editMode ? (
                <Formik
                  initialValues={{
                    name: guardData.name,
                    username: guardData.username,
                    userPhoneNo: guardData.userPhoneNo,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form className="form_setting">
                      <div className="input_setting">
                        <label>
                          {" "}
                          {language === "hindi" ? "Name " : "नाम"}
                        </label>
                        <br />
                        <Field
                          type="text"
                          className="setting_input"
                          name="name"
                          maxLength={35}
                        />
                        {errors.name && touched.name ? (
                          <div className="error error_edit">{errors.name}</div>
                        ) : null}
                      </div>
                      <div className="input_setting">
                        <label>
                          {" "}
                          {language === "hindi" ? "Useremail " : "ईमेल"}
                        </label>
                        <br />
                        <Field
                          type="text"
                          className="setting_input"
                          name="username"
                        />
                        {errors.username && touched.username ? (
                          <div className="error error_edit">{errors.username}</div>
                        ) : null}
                      </div>
                      <div className="input_setting">
                        <label>
                          {" "}
                          {language === "hindi" ? "Phone No. " : "फ़ोन नं"}
                        </label>
                        <br />
                        <Field
                          type="text"
                          className="setting_input"
                          name="userPhoneNo"
                        />
                        {errors.userPhoneNo && touched.userPhoneNo ? (
                          <div className="error error_edit">{errors.userPhoneNo}</div>
                        ) : null}
                      </div>
                      <br />
                      <div className="action-button_setting">
                        <button type="submit">Save</button>
                        <button type="button" onClick={toggleEditMode}>
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="user-content_admin">
                  <div className="user_inner_content">
                    <span>{language === "hindi" ? "Username " : "नाम"}</span>
                    <br />
                    <span> {language === "hindi" ? "Useremail " : "ईमेल"}</span>
                    <br />
                    <span>
                      {language === "hindi" ? "Phone No. " : "फ़ोन नं"}
                    </span>
                    <br />
                    <span>{language === "hindi" ? "Role " : "भूमिकाएँ"}</span>
                  </div>
                  <div className="guardData_div">
                    <b>{guardData.name}</b>
                    <br />
                    <b>{guardData.username}</b>
                    <br />
                    <b>{guardData.contactName || guardData.userPhoneNo}</b>
                    <br />
                    <b>{guardData.role}</b>
                    <br />
                  </div>
                </div>
              )}
            </div>
            <div className="action-button">
              <button onClick={toggleEditMode}>
                <CiEdit className="edit_setting_icon" />
                &nbsp;Edit
              </button>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default AdminProfileSetting;

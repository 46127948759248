import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { FaUser } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { FaAddressCard } from "react-icons/fa";
import { FaImages } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { LanguageContext } from "../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
const RegularUsersImages = ({ item }) => {
  const { language } = useContext(LanguageContext);
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const imagePath =
    item && item.image && Array.isArray(item.image) && item.image.length > 0
      ? item.image.map((path) => path && path?.substring(path.indexOf("/") + 1))
      : null;
  const imagePathAadhar =
    item &&
    item.aadharImage &&
    Array.isArray(item.aadharImage) &&
    item.aadharImage.length > 0
      ? item.aadharImage.map(
          (path) => path && path?.substring(path.indexOf("/") + 1)
        )
      : null;
  const imagePathOptional =
    item &&
    item.optionalImage &&
    Array.isArray(item.optionalImage) &&
    item.optionalImage.length > 0
      ? item.optionalImage.map(
          (path) => path && path?.substring(path.indexOf("/") + 1)
        )
      : null;
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <h6 className="regular_Details_view">
            <IoEyeSharp data-toggle="tooltip" className="eyes_view" data-placement="top" title={language === "hindi"
                            ? "view"
                            : "देखना"}/>
            </h6>
          </Button>
          <Drawer
            className="regular_drawer"
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div className="heading_user">
              <div className="regular_btn_div">
                <button
                  className=""
                  onClick={toggleDrawer(anchor, false)}
                >
                  <IoMdClose className="" />
                </button>
              </div>
              <div className="user_detail">
                <h1 className="scoiety_deatil_heading">
                  {language === "hindi" ? " User Details" : " उपयोगकर्ता विवरण"}
                </h1>
              </div>
            </div>
            <hr className="hr_line" />
            <List>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp; {language === "hindi" ? " Name " : " नाम"}
                  </h3>
                  <h3 className="house_title_left">
                    <ImUsers />
                    &nbsp; {language === "hindi" ? "Gender" : " लिंग"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp; {language === "hindi" ? "Address" : " पता"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{" "}
                    {language === "hindi" ? "Aadhaar Number" : " आधार संख्या"}
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={item.id}>
                    <h3 className="house_title_left ">
                      {item.houseMaidEnglish}
                    </h3>
                    <h3 className="house_title_left">{item.gender}</h3>
                    <h3 className="house_title_left">{item.address}</h3>
              
                    <h3 className="house_title_left">{item.aadharNumber}</h3>
                  </React.Fragment>
                </div>
              </div>

              <div className="user_images_bottom_div">
                <h3 className=" user_images_heading user_images_div_regular">
                  &nbsp; &nbsp; <FaImages />
                  &nbsp;{" "}
                  {language === "hindi" ? "User Images" : "  उपयोगकर्ता छवियाँ"}
                </h3>
                <div className="user_images_div  ">
                  {imagePath &&
                    imagePath.map((item, index) => (
                      <img key={index} src={`/${item}`} alt="" />
                    ))}
                </div>
              </div>
              <div className="user_images_bottom_div">
                <h3 className="user_images_heading user_images_div_regular">
                  &nbsp; &nbsp; <FaImages />
                  &nbsp;{" "}
                  {language === "hindi"
                    ? "Aadhaar Card Images "
                    : " आधार कार्ड छवियाँ"}
                </h3>
                <div className="user_images_div">
                  {imagePathAadhar &&
                    imagePathAadhar.map((item, index) => (
                      <img key={index} src={`/${item}`} alt="" />
                    ))}
                </div>
              </div>
              <div className="user_images_bottom_div">
                <h3 className=" user_images_heading_optional">
                  <FaImages />
                  &nbsp;{" "}
                  {language === "hindi"
                    ? "Other documents(Pan Card,Driving Licence or Other) "
                    : "  अन्य दस्तावेज़ (पैन कार्ड, ड्राइविंग लाइसेंस या अन्य)"}
                </h3>
                <div className="user_images_div">
                  {imagePathOptional && imagePathOptional.length > 0 ? (
                    imagePathOptional.map((item, index) => (
                      <img
                        key={index}
                        src={`/${item}`}
                        alt={`Optional Image ${index + 1}`}
                      />
                    ))
                  ) : (
                    <div className="not_Added">
                      {" "}
                      <b>
                        {" "}
                        {language === "hindi"
                          ? "Not Added ! "
                          : " नहीं जोड़ा गया!"}
                      </b>
                    </div>
                  )}
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default RegularUsersImages;

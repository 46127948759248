import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import { FaUser } from "react-icons/fa";
import { ImUsers } from "react-icons/im";
import { FaAddressCard } from "react-icons/fa";
import { FaImages } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { LanguageContext } from "../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";

const GetSocietyDetails = ({ item }) => {
  const { language } = useContext(LanguageContext);
  // console.log(item)
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <h5 className="all_data mt-2">
              <IoEyeSharp
                data-toggle="tooltip"
                className="eyes_view"
                data-placement="top"
                title={language === "hindi" ? "view" : "देखना"}
              />
            </h5>
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            sx={{
              width: "57vw",
              maxWidth: "15vw",
            }}
          >
            <div className="heading_user">
              <div>
                <Button
                  className="close_btn"
                  onClick={toggleDrawer(anchor, false)}
                >
                  <IoMdClose className="close_icons" />
                </Button>
              </div>
              <div className="user_detail">
                <h1 className="scoiety_deatil_heading">
                  {language === "hindi" ? " Society Details" : " सोसायटी विवरण"}
                </h1>
              </div>
            </div>
            <hr className="hr_line" />
            <List>
              <div className="main_content_div">
                <div className="content2_left_div">
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp;{" "}
                    {language === "hindi" ? " Society Name" : " सोसायटी का नाम"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaUser />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? "Society Registration No."
                      : "पंजीकरण नं."}
                  </h3>
                  <h3 className="house_title_left">
                    <ImUsers />
                    &nbsp;{language === "hindi" ? " Address " : " पता"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp; {language === "hindi" ? " Contact " : " संपर्क"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? "Alternate No. "
                      : "वैकल्पिक संपर्क"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? " Society Login Email  "
                      : "सोसायटी उपयोगकर्ता ईमेल"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? "Society Login Password "
                      : "सोसायटी उपयोगकर्ता पासवर्ड"}{" "}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? " Secretary Name "
                      : "  सचिव का नाम"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? " Secretary Contact No."
                      : " सचिव संपर्क नं."}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi" ? " Owner Name" : " मालिक का नाम"}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? " Owner Contact No."
                      : " मालिक का संपर्क नं."}
                  </h3>
                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;{" "}
                    {language === "hindi"
                      ? " Super Admin Name "
                      : " सुपर प्रशासक का नाम"}
                  </h3>

                  <h3 className="house_title_left">
                    <FaAddressCard />
                    &nbsp;
                    {language === "hindi"
                      ? " Super Admin Contact No."
                      : "सुपर प्रशासक का संपर्क नं."}
                  </h3>
                </div>
                <div className="content2_right_div">
                  <React.Fragment key={item.id}>
                    <h3 className="house_title_left">{item.name}</h3>
                    <h3 className="house_title_left">
                      {item.societyRegistration}
                    </h3>
                    <h3 className="house_title_left">{item.address}</h3>
                    <h3 className="house_title_left">{item.contactName}</h3>
                    <h3 className="house_title_left">{item.alternateNumber}</h3>
                    <h3 className="house_title_left">{item.username}</h3>
                    <h3 className="house_title_left">{item.password}</h3>
                    <h3 className="house_title_left">{item.secretaryName}</h3>

                    <h3 className="house_title_left">
                      {item.secretaryContact}
                    </h3>

                    <h3 className="house_title_left">{item.ownerName}</h3>

                    <h3 className="house_title_left">{item.userPhoneNo}</h3>
                    <h3 className="house_title_left">{item.superAdminName}</h3>
                    <h3 className="house_title_left">
                      {item.superAdminContactNo}
                    </h3>
                  </React.Fragment>
                </div>
              </div>

              <div className="user_images_bottom_div">
                <h3 className=" user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi"
                    ? "Super Admin Photo"
                    : "सुपर प्रशासक का फोटो"}
                </h3>
                <div className="user_images_div">
                  {Array.isArray(item.superAdminPhoto) &&
                    item.superAdminPhoto.map((path, index) => (
                      // console.log(path),
                      <img
                        key={index}
                        src={`/${path.replace(/^public\/?/, "")}`}
                        alt=""
                      />
                    ))}
                </div>
                <h3 className=" user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi"
                    ? "Super Admin Documents"
                    : "सुपर प्रशासक के दस्तावेज़"}
                </h3>

                <div className="user_images_div">
                  {Array.isArray(item.superAdminDocument) &&
                    item.superAdminDocument.map((path, index) => (
                      // console.log(path),
                      <img
                        key={index}
                        src={`/${path.replace(/^public\/?/, "")}`}
                        alt=""
                      />
                    ))}
                </div>
              </div>

              <div className="user_images_bottom_div">
                <h3 className=" user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi" ? "Secretary photo" : "  सचिव का फोटो"}
                </h3>
                <div className="user_images_div">
                  {Array.isArray(item.secretaryPhoto) &&
                    item.secretaryPhoto.map((path, index) => (
                      // console.log(path),
                      <img
                        key={index}
                        src={`/${path.replace(/^public\/?/, "")}`}
                        alt=""
                      />
                    ))}
                </div>
              </div>

              <div className="user_images_bottom_div">
                <h3 className=" user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi" ? "Society Logo" : "सोसायटी लोगो"}
                </h3>
                <div className="user_images_div">
                  {item?.societyLogo ? (
                    // console.log(path),
                    <img
                      src={`/${item.societyLogo.replace(/^public\/?/, "")}`}
                      alt=""
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="user_images_bottom_div">
                <h3 className="user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi" ? "Secretary Details" : " सचिव विवरण"}
                </h3>
                <div className="user_images_div">
                  {Array.isArray(item.secretaryDetails) &&
                    item.secretaryDetails.map((path, index) => (
                      <img
                        key={index}
                        src={`/${path.replace(/^public\/?/, "")}`}
                        alt=""
                      />
                    ))}
                </div>
              </div>
              <div className="user_images_bottom_div">
                <h3 className="user_images_heading_society">
                  <FaImages />
                  &nbsp;
                  {language === "hindi" ? "Society Images" : "सोसायटी छवियाँ"}
                </h3>
                <div className="user_images_div">
                  {Array.isArray(item.societyImages) &&
                    item.societyImages.map((path, index) => (
                      <img
                        key={index}
                        src={`/${path.replace(/^public\/?/, "")}`}
                        alt=""
                      />
                    ))}
                </div>
              </div>
            </List>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default GetSocietyDetails;

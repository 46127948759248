import React, { useContext, useEffect, useState } from "react";
import { RxEyeOpen } from "react-icons/rx";
import { DNA } from "react-loader-spinner";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LanguageContext } from "../lib/LanguageContext";
import { IoEyeSharp } from "react-icons/io5";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-45%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  zIndex: 1000,
};

const PreviewImage = ({ item }) => {
  const { language } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });
  return (
    <div>
      <Button onClick={handleOpen}>
        <h5 className="all_data mt-3">
          <IoEyeSharp
            data-toggle="tooltip"
            className="eyes_view"
            data-placement="top"
            title={language === "hindi" ? "view" : "देखना"}
          />
        </h5>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="button_cross button_close_guest">
            <Button onClick={handleClose}>
              <RxCross2 className="cross_icon" />
            </Button>
          </div>
          <h6 className="center_image">
            {language === "hindi" ? "Aadhaar Images" : "आधार छवि"}
          </h6>
          {loading ? (
            <div className="loader_div">
              <DNA
                visible={true}
                height="90"
                width="90"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <div className="user_images_div_getData_aadharImg">
              {item?.adharImg?.length>0?Array.isArray(item.adharImg) &&
                item.adharImg.map((path, index) => (
                  <img
                    key={index}
                    src={`/${path.replace("public/", "")}`}
                    alt=""
                  />
                )):<div className="text-center w-100 p-4">
                  {language === "hindi" ? "No Images" : "कोई चित्र नहीं"}</div>
                }
            </div>
          )}
           <br/>
          <h6 className="center_image">
            {language === "hindi" ? "User Images" : " उपयोगकर्ता छवि"}
          </h6>
          {loading ? (
            <div className="loader_div">
              <DNA
                visible={true}
                height="90"
                width="90"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
              />
            </div>
          ) : (
            <div className="user_images_div_getData">
              {Array.isArray(item.image) &&
                item.image.map((path, index) => (
                  <img
                    key={index}
                    src={`/${path.replace("public/", "")}`}
                    alt=""
                  />
                ))}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default PreviewImage;
